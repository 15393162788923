body{
    background-color: white !important;
}
.container{
    margin-top: 80px;
}

.col-md-12.row.row-cards{
    margin: 1rem 0;
}

a.link-route,
a.link-route:hover{
    text-decoration: none;
    color: unset;
}

a.mb-3{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.producto a.mb-3{
    height: 125.5px;
}

.categoria .card.p-3 h4{
    font-size: 0.7rem;
}

.card.p-3 h4{
    font-size: 0.85rem;
}

.categoria .card{
    box-shadow: unset !important;
    margin-bottom: 0.5rem !important;
    border-radius: 0 !important;
}

.producto .link-route .card h4{
    height: 1.7rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.producto .link-route .card span{
    color: red;
}

@media(min-width: 991px){
    .producto a.mb-3{
        height: 330px;
    }

    .categoria .card.p-3 h4{
        font-size: 0.61rem;
    }

    .card.p-3 h4{
        font-size: 1.125rem;
    }

    .producto .link-route .card h4{
        height: 2.5rem;
        text-overflow: ellipsis
    }
}

a.mb-3 img{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    display: inline-block;

}

.no-padding{
    padding: 0 !important;
}

.list-group-item{
    padding: 0.75rem 0 !important;
}

.categoria .d-flex.align-items-center.px-2.justify-content-center{
    height: 1.25rem;
}

.d-flex.align-items-center.px-2.justify-content-center {
    text-align: center;
    height: 3rem;
}

.container{
    padding-top: 0.75rem;
}

.ProductImage{
    border: 1px solid #fafafa;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProductImage img{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.form-group.row{
    display: flex !important;   
}

.login-container{
    min-height: 85vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown.nav-item{
    padding: 0 !important;
}

.image-gallery-slide img {
    width: auto !important;
    height: auto;
    max-height: 360px;
    cursor: zoom-in;
}

.image-gallery{
    width: 100% !important;
}

.image-gallery-slide{
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fafafa;
    width: 100%;
    padding: 2px;
}

.loader-page{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000073;
}

.loader-page.hide{
    display: none;
}

.pagos-container{
    padding: 2rem;
}

.pago-card{
    border: 3px solid;
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 2rem;
    min-height: 10rem;
    cursor: pointer;
}

.pago-card.deposito{
    border-color: blue;
}

.pago-card.entrega{
    border-color: green;
}

.pago-card.deposito .pago-card-title{
    background: blue;
}

.pago-card.entrega .pago-card-title{
    background: green;
}

.pago-card-title{
    width: 70%;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    color: white;
    margin-bottom: 15px
}


.pago-card p{
    margin-bottom: 0 !important;
}

.dropdown-menu {
    right: 0 !important;
    left: unset !important;
}

.menu-container{
    display: flex;
    flex-direction: column;
}

@media(min-width: 991px){
    .menu-container{
        flex-direction: row;
    }
    
}

.vendidos{
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 0;
    border-bottom: 2px solid #fafafa;
}

.vendidos .imagen{
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fafafa;
    padding: 0px !important;
}

.vendidos .imagen img{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.detalle-vendidos{
    margin-bottom: 0 !important;
}

@media(max-width: 360px){
    .titulo-vendidos{
        font-size: 0.75rem !important;
    }

    .detalle-vendidos{
        font-size: 0.7rem !important; 
    }
}

@media(min-width: 640px){
    .vendidos .imagen{
        height: 164px;
    }
}

@media(min-width: 992px){
    .vendidos .imagen{
        height: 225px;
    } 
}

.dzu-previewContainer{
    display: none !important;
}

.producto-preview{
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fafafa;
    padding: 5px;
   
}

.preview-hover{
    position: absolute !important;
    background: #0000004d;
    height: 100%;
    color: white;
    display: flex;  
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.preview-hover span{
    cursor: pointer
}

.producto-preview img{
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: auto;
}

.update-bar{
    background-color: #2f5596;
    padding: 1rem;
    color: white;
    text-align: center;
}

.update-bar p{
    margin-bottom: 0 !important;
}

.update-bar a{
    color: #ffc000;
}

.add-icon{
    cursor: pointer; 
    position: absolute;
    right: 1.2rem;
    color: blue;
}

.add-icon img{
    width: 1.2rem;
}

.capitalize{
    text-transform: capitalize;
}

.img-envio{
    height: 7rem;
    width: 7rem;
    padding: 1rem;
    border: 4px solid;
    border-radius: 10px;
    cursor: pointer;
}

.img-envio.active{
    border-color: green;
}

.no-margin{
    margin: 0 !important;
}

.vendedor-link{
    font-size: 0.7rem;
    color: #bba9a9 !important;
}

.chat-vendedor{
    font-size: 1.5rem;
    color: red !important;
}

.messages-container{
    display: flex;
    flex-direction: column-reverse;
    min-height: 55vh !important;
    max-height: 55vh;
    overflow-y: auto;
}

.message{
    display: flex;
    margin: 0.5rem;
    align-items: center;
    word-wrap: none;
}

.message.right{
    justify-content: flex-end;
}

.message.left{
    justify-content: flex-start;
}

.message p{
    max-width: 80%;
    padding: 1rem;
    border-radius: 10px;
    word-break: break-word;
}

.message.right p{
    background-color: springgreen;
}

.message.left p{
    background-color: steelblue;
}

.message-write{
    display: flex;
    padding-bottom: 0.5rem;
}

.message-write textarea{
    resize: none;
    width: 100%;
}

.message-img{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 5rem;
}

.message-icon{
    margin-left: 19vw;
}

@media(min-width: 570px){
    .message-icon{
        margin-left: 45vw;
    }    
}

@media(min-width: 720px){
    .message-icon{
        margin-left: 59vw;
    }    
}

@media(min-width: 992px){
    .message-icon{
        margin-left: 52vw;
    }    
}

@media(min-width: 1050px){
    .message-icon{
        margin-left: 55vw;
    }    
}

@media(min-width: 1150px){
    .message-icon{
        margin-left: 59vw;
    }    
}

@media(min-width: 1272px){
    .message-icon{
        margin-left: 63vw;
    }    
}

@media(min-width: 1360px){
    .message-icon{
        margin-left: 65vw;
    }    
}

@media(max-height: 375px){
    .messages-container{
        max-height: 47vh;
    }
}

.icono-tracking-container{
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
}


@media(max-width: 360px){
    .icono-tracking{
        width: 30vw;
    }
}

.icono-tracking{
    width: 20vw;
    height: auto;
    fill: lightgray;
}



.marker-map{
    height: 40px;
    width: 40px;
    margin-top: -45px;
    margin-left: -20px;
}

.footer{
    width: 100vw;
    margin: 0;
    padding: 1rem 2rem;
    background-color: #f8f9fa !important;
}

.copyright{
    padding: 1rem;
}

.copyright h4{
    text-align: center;
    color: black;
    margin: 0;
}

.icono-social {
    padding: 0.25rem;
}

.icono-social img{
    width: 2rem;
    height: auto;
}

.link{
    color: #007bff !important;
    cursor: pointer;
}

.link:hover{
    text-decoration: underline !important;
}

.no-reading{
    border-radius: 15px;
    padding: 0px 6px;
    background-color: red;
    color: white;
    display: inline;
}

.banner{
    width: 100%;
}

.icon-grid{
    cursor: pointer;
    width: 1.5rem;
    margin: 0.25rem;
    fill: gray;
}

.icon-grid.selected{
    fill: #2f5596 !important;
}

@media(min-width: 1367px){
    .container{
        max-width: 70vw !important;
    }

    .col-xl-4.producto{
        max-width: 25% !important;
    }
}

.term-title{
    margin-bottom: 0 !important;
}

.term-par{
    margin-top: 2rem !important;
}

.banner-info{
    width: 100%;
    background-color: #315695;
    padding: 2rem 1rem;
    display: flex;
    flex-wrap: wrap;
}

.banner-col{
    color: white;
    border-radius: 10px;
    padding: 1.5rem 1rem;
    margin: 0.5rem 0;
    width: 100%;
    text-align: center;
}

.banner-col h3{
    margin-bottom: 0 !important;
    font-weight: 500;
}
.banner-col img{
    max-width: 50%;
    height: auto;
}

.banner-col.red{
    background-color: #d3383c
}

.banner-col.purple{
    background-color: #953797
}

.banner-col.green{
    background-color: #ebb304
}

.banner-col.yellow{
    background-color: #00c559
}

.statcounter{
    display: none !important;
}

.boton-vender{
    position: fixed;
    padding: 1rem;
    background-color: #315695;
    color: white !important;
    bottom: 1rem;
    right: 3rem;
    border-radius: 10px;
    z-index: 99999;
}


@media(min-width: 992px){
    .boton-vender{
        display: none;
    }
}

.header-menu{
    padding: 0.5rem 0.75rem;
    transition: 0.3s color;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin-left: 1rem;
    color: #fff;
}

.header-menu svg{
    margin-right: 0.25rem;
}

.header-menu:hover{
    text-decoration: none;
}

.header-menu.inicio{
    background-color: #2f5495;
}

.header-menu.tracking{
    background-color: #ffc000;
}

.header-menu.vender{
    background-color: green;
}

.menu-visible{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.image-list{
    justify-content: center;
    align-items: center;
    height: 20vw !important;
    border: 1px solid #eae9e9
}

.image-list img{
    max-height: 20vw;
}

a.list-group-item {
    color: black;
}

.checkTerm{
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    outline: none;
  }
  .switch input {
    position: absolute;
    top: -99999px;
    left: -99999px;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  input:checked + .slider {
    background-color: #2196F3;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .list-group{
      width: 100% !important;
  }

.fullsize .image-gallery-slides,
.fullsize .image-gallery-slide {
    height: 70vh !important;
    
}

.fullsize .image-gallery-image {
    width: 100%;
    height: 100%;
    display: flex; 
    justify-content: center;
    align-items: center;
}

.fullsize .image-gallery-image img{
    max-height: 70vh !important;
    cursor: auto;
}

a.image-gallery-thumbnail {
    width: 60px;
    height: 60px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-gallery-thumbnails-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

button.close span{
    display: none !important;
}

.breadcrumb{
    background: none !important;
}


img.rotateXP,
.rotateXP img{
    transform: rotate(90deg);
}

img.rotateXN,
.rotateXN img{
    transform: rotate(-90deg);
}

img.rotateY,
.rotateY img{
    transform: rotate(180deg);
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin: 0 5px
}

.currency-icon{
    margin: 3px;
    line-height: normal;
    font-size: x-large;
}